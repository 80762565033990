.tableView_logo {
    width: 200px !important;
}

.tableView_header {
    background-color: #4793B5 !important;
    border: none !important;
    outline: none !important;
    color: white !important;
    font-size: 1.25rem !important;
    letter-spacing: -0.2px !important;
    height: 40px !important;
}

.tableView_grid {
    margin-top: 20px;
}

.tableView_form_input {
    margin: 10px auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableView_icon_input {
    position: absolute;
    left: 35px;
    top: 0;
    font-size: 25px;
    color: #B5C0C5;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableView_send_button {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    color: white !important;
    background: linear-gradient(270deg, #2b8991 40.12%, rgba(31, 49, 53, 1) 156.6%) !important;
    filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.25)) !important;
    border-radius: 20px !important;
    text-align: center !important;
    border: none !important;
}

.tableView_cancel_button {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    color: #8F8F8F !important;
    background: #DEDEDE !important;
    filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.35)) !important;
    border-radius: 20px !important;
    text-align: center !important;
    border: none !important;
}

.tableView_send_button:focus {
    box-shadow: none !important;
    outline: none !important;
    outline-offset: none !important;
}

.tableView_cancel_button:focus {
    box-shadow: none !important;
    outline: none !important;
    outline-offset: none !important;
}

.tableView_send_button:hover {
    background: linear-gradient(270deg, #6aacb2 40.12%, rgba(31, 49, 53, 1) 156.6%) !important;
}

.tableView_cancel_button:hover {
    background: #e7e7e7 !important;
}

.tableView_personal_wrapp {
    text-align: left !important;
    color: #8F8F8F !important;
}

.tableView_input {
    font-size: 18px !important;
    line-height: 30px !important;
    outline: 0;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
}

.tableView_label_title {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #8F8F8F;
    float: left !important;
}

.tableView_label_content {
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    color: #696969;
    float: left !important;
}

.tableView_input::placeholder {
    font-style: normal;
    font-weight: 400;
}

.tableView_span {
    text-align: left !important;
    justify-content: flex-start !important;
    display: "flex" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #8F8F8F;
}

.tableView_buttons_wrapper {
    text-align: right;
}

.tableView_edit_button {
    color: #72BF80 !important;
}

.tableView_delete_button {
    color: #DE2C2C !important;
}