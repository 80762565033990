.setPasswordlogo {
    width: 250PX !important;
    text-align: center !important;
    margin-bottom: -20px !important;
}

.setPassword_modal_body {
    padding: 25px !important;
}

.setPasswordform_input {
    margin: 10px auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.setPasswordinput {
    border: 1px solid rgba(0, 0, 0, 0.28);
    padding: 5px 10px 5px 10px;
    font-size: 18px;
    line-height: 30px;
    outline: 0;
    display: block;
    width: 90%;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
}

.setPasswordinput::placeholder {
    color: #B5C0C5;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
}

.setPasswordsend_button {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    color: white !important;
    background: linear-gradient(270deg, #2b8991 40.12%, rgba(31, 49, 53, 1) 156.6%) !important;
    filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.25)) !important;
    border-radius: 20px !important;
    text-align: center !important;
    border: none !important;
}

.setPasswordsave_button_success {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    color: #fff !important;
    background: #19AC30 !important;
    border-radius: 5px !important;
    text-align: center !important;
    border: none !important;
}

.setPasswordsave_button_error {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    color: #8F8F8F !important;
    background: #CCCCCC !important;
    border-radius: 5px !important;
    text-align: center !important;
    border: none !important;
}

.setPasswordsend_button:focus {
    box-shadow: none !important;
    outline: none !important;
    outline-offset: none !important;
}

.setPasswordsave_button:focus {
    box-shadow: none !important;
    outline: none !important;
    outline-offset: none !important;
}

.setPasswordsend_button:hover {
    background: linear-gradient(270deg, #6aacb2 40.12%, rgba(31, 49, 53, 1) 156.6%) !important;
}

.setPasswordsave_button:hover {
    background: #e7e7e7 !important;
}

.setPasswordicon_visibility_wrapper {
    position: absolute;
    float: right;
    left: 360px;
    top: 0;
    font-size: 25px;
    color: #B5C0C5 !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.setPasswordicon_visibility {
    color: #B5C0C5 !important;
}

.passwordValidationError {
    color: #EC5F5F;
    margin-left: 5px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 17px !important;
}

.passwordValidationSuccess {
    color: #B2C8A7;
    margin-left: 5px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 17px !important;
}

.passwordValidationError_icon {
    color: #EC5F5F;
}

.passwordValidationSuccess_icon {
    color: #19AC30;
}

.passwordValidation_wrapper {
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    margin-top: 20px;
}