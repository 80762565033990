.forgotPassword_logo {
  width: 250px !important;
  text-align: center !important;
}

.forgotPassword_form_input {
  margin: 10px auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgotPassword_icon_input {
  position: absolute;
  left: 70px;
  top: 0;
  font-size: 25px;
  color: #B5C0C5;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgotPassword_input {
  border: 1px solid rgba(0, 0, 0, 0.28);
  padding: 5px 10px 5px 50px;
  font-size: 18px;
  line-height: 30px;
  outline: 0;
  display: block;
  width: 350px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
}

.forgotPassword_input::placeholder {
  color: #B5C0C5;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
}

.forgotPassword_send_button {
  width: 350px !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #3C7F9C !important;
  background: #ffff !important;
  border-radius: 10px !important;
  border: 2px solid #3C7F9C !important;
}


.forgotPassword_send_button:hover {
  background: #3C7F9C !important;
  color: #fff !important;
}

.forgotPassword_cancel_button {
  width: 350px !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #696969 !important;
  background: #CDCDCD !important;
  border-radius: 10px !important;
  border: none !important;
}

.forgotPassword_buttons_wrapper {
  justify-content: center !important;
}

.forgotPassword_send_button:focus {
  box-shadow: none !important;
  outline: none !important;
  outline-offset: none !important;
}

.forgotPassword_cancel_button:focus {
  box-shadow: none !important;
  outline: none !important;
  outline-offset: none !important;
}


.forgotPassword_cancel_button:hover {
  background: #e7e7e7 !important;
}