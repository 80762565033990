.language_switch_header {
  border: none;
  color: white;
  width: 30px;
  background-size: 30px;
  background-color: transparent;
  vertical-align: middle;
}

.App {
  background-color: white;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: url(../../../images/login_background.jpg);
  background-size: 2050px;
  background-position: center;
  left: 0;
}

.form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 40px;
  border-radius: 10px;
  background-color: #F9F9F9;
  height: 530px;
}

.form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.form-title {
  font-style: normal;
  font-weight: 700;
  color: #8F8F8F;
  text-align: center;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.wrapper_input {
  position: relative;
}

.login_logo {
  width: 250px;
  text-align: center !important;
}

.login_button {
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #3C7F9C !important;
  background: #ffff !important;
  border-radius: 10px !important;
  border: 2px solid #3C7F9C !important;
}

.login_button:hover {
  background: #3C7F9C !important;
  color: #fff !important;
}

.login_button:focus {
  box-shadow: none !important;
  outline: none !important;
  outline-offset: none !important;
}

.form-input {
  margin: 10px auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_input {
  position: absolute;
  left: 15px;
  top: 0;
  font-size: 25px;
  color: #B5C0C5;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_visibility_wrapper {
  position: absolute;
  float: right;
  left: 270px;
  top: 0;
  font-size: 25px;
  color: #B5C0C5 !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_visibility {
  color: #B5C0C5 !important;
}

.input {
  border: 1px solid rgba(0, 0, 0, 0.28);
  padding: 5px 10px 5px 50px;
  font-size: 18px;
  line-height: 30px;
  outline: 0;
  display: block;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
}

.input::placeholder {
  color: #B5C0C5;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
}

.logo_wrapper {
  text-align: center;
}

.title_wrapper {
  padding: 10px;
}

.forgot_label {
  display: inline-block;
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: rgba(143, 143, 143, 0.69);
  cursor: pointer;
}

.forgot_label:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #3C7F9C;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.forgot_label:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.forgot_label_wrapper {
  text-align: center;
}

.followUs_wrapper {
  margin-top: 50px;
  text-align: center;
}

.social_icons_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.055em;
  text-align: center;
  color: #8F8F8F;
  font-style: normal;
}

.social_icons {
  font-size: 25px;
  text-align: center;
}

.social_icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  color: black;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: all .2s ease-in-out !important;
  padding: 5px;
}

.social_icons_facebook {
  color: #4267B2;
}

.social_icons_instagram {
  color: #C13584;
}

.social_icons_linkedIn {
  color: #0077B5;
}

.social_icons a:hover {
  transform: scale(1.2) !important;
}

@media only Screen and (max-width: 420px) {
  .icon_visibility_wrapper {
    left: 250px;
  }
}