.addNewAccount_logo {
    width: 200px !important;
    text-align: center !important;
    padding: 20px !important;
}

.addNewAccount_form_input {
    margin: 10px auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.addNewAccount_modal_body {
    padding: 25px;
    background: #E6ECEF;
}

.addNewAccount_grid {
    margin-top: 20px;
}

.addNewAccount_icon_input {
    position: absolute;
    left: 35px;
    top: 0;
    font-size: 25px;
    color: #B5C0C5;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.addNewAccount_send_button {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    color: white !important;
    background: linear-gradient(270deg, #2b8991 40.12%, rgba(31, 49, 53, 1) 156.6%) !important;
    filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.25)) !important;
    border-radius: 20px !important;
    text-align: center !important;
    border: none !important;
}

.addNewAccount_cancel_button {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    color: #8F8F8F !important;
    background: #DEDEDE !important;
    filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.35)) !important;
    border-radius: 20px !important;
    text-align: center !important;
    border: none !important;
}

.addNewAccount_send_button:focus {
    box-shadow: none !important;
    outline: none !important;
    outline-offset: none !important;
}

.addNewAccount_cancel_button:focus {
    box-shadow: none !important;
    outline: none !important;
    outline-offset: none !important;
}

.addNewAccount_send_button:hover {
    background: linear-gradient(270deg, #6aacb2 40.12%, rgba(31, 49, 53, 1) 156.6%) !important;
}

.addNewAccount_cancel_button:hover {
    background: #e7e7e7 !important;
}

.addNewAccount_personal_wrapp {
    color: #8F8F8F !important;
}

.addNewAccount_input {
    padding: 5px 5px 5px 10px !important;
    font-size: 18px !important;
    line-height: 30px !important;
    outline: 0;
    display: block;
    width: 98% !important;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.28);
    box-shadow: none !important;
}

.addNewAccount:focus {
    box-shadow: none !important;
}

.addNewAccount_input::placeholder {
    font-style: normal;
    font-weight: 400;
}

.addNewAccount_span {
    text-align: left !important;
    justify-content: flex-start !important;
    display: flex !important;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #8F8F8F;
}

.addNewAccount_buttons_wrapper {
    text-align: right;
}

.addNewAccount_edit_button {
    color: #8F8F8F !important;
    margin-left: 5px !important;
}

.addNewAccount_delete_button {
    color: #DE2C2C !important;
    float: left !important;
    cursor: pointer !important;
}

.addNewAccount_save_button {
    color: #72BF80 !important;
    margin-left: 5px !important;
}

.addNewAccountModal_header {
    background-color: #4793B5;
    border: none;
    outline: none;
    color: white;
    font-size: 1.25rem !important;
    letter-spacing: -0.2px !important;
    height: 40px !important;
}

.label_input {
    display: flex !important;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #696969;
}

.addNewAccount_icon_div {
    border: none !important;
    outline: none !important;
    background: #E6ECEF !important;
}

.formControl_feedback {
    font-size: 13px !important;
    align-content: center !important;
    align-self: center !important;
    text-align: right !important;
}

.reactSelect_street {
    line-height: 27px !important;
    margin-right: 5px !important;
}

.addNewAccount_upload_wrapper {
    justify-content: center !important;
    display: flex !important;
}

.addNewAccount_upload_container {
    width: 200px !important;
    height: 200px !important;
    background: #F3F3F3 !important;
    display: "flex";
    align-content: center !important;
}

.addNewAccount_upload_container h4 {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
}