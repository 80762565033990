::-webkit-scrollbar{
    width: 12px;
  }
  ::-webkit-scrollbar-track{
    border-radius: 10px;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.25);
  }
  ::-webkit-scrollbar-thumb{
    background: rgba(132, 155, 164, 0.5);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover{
    background-color: rgba(93, 147, 168, 0.5);
  }