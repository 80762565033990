.uploadButton_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50;
}

.uploadButton_preview {
    margin-top: 50;
    display: flex;
    flex-direction: column;
}

.uploadButton_image {
    max-width: 150px;
    max-height: 150px;
    min-width: 150px;
    min-height: 150px;
    border-radius: 50%;
}

.uploadButton_button {
    margin-top: 3px !important;
    background: transparent !important;
    border: 1px solid gray !important;
    padding: 2px !important;
}

.uploadButton_button_remove-image {
    border-radius: 5px !important;
    padding: 2px 6px 3px !important;
    text-decoration: none !important;
    font: 400 12px sans-serif !important;
    background: #d85353 !important;
    border: 1px solid #fff !important;
    color: #FFF !important;
    width: 40px !important;
}

.uploadButton_button_edit-image {
    border-radius: 5px !important;
    padding: 2px 6px 3px !important;
    text-decoration: none !important;
    font: 400 12px sans-serif !important;
    background: #3c83c1 !important;
    border: 1px solid #fff !important;
    color: #FFF !important;
    width: 40px !important;
}

.uploadButton_upload_container {
    width: 200px !important;
    height: 200px !important;
    background: #F3F3F3 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #85858547;
    border-radius: 10px;
}

.uploadButton_upload_container:hover {
    border: 1px solid #00000047;
}

.uploadButton_icon_publish {
    color: #696969 !important;
    font-size: 50px !important;
    align-content: center !important;
    align-items: center !important;
}

.uploadButton_text_publish {
    color: #696969 !important;
    font-size: 21px !important;
}

.uploadButton_label_publish:hover {
    transform: scale(1.05) !important;
    transition: ease-in-out calc(0.2s) !important;
    cursor: pointer !important;
}