.accordion_passwordValidation_container {
    line-height: 10px !important;
}

.accordion_input {
    box-shadow: none !important;
}

.accordion_input:focus {
    border: 1px solid #dadfe3 !important;
    border-right: none !important;
}

.setPasswordsend_button:focus {
    box-shadow: none !important;
    outline: none !important;
    outline-offset: none !important;
}

.setPasswordsave_button:focus {
    box-shadow: none !important;
    outline: none !important;
    outline-offset: none !important;
}

.setPasswordsend_button:hover {
    background: linear-gradient(270deg, #6aacb2 40.12%, rgba(31, 49, 53, 1) 156.6%) !important;
}

.setPasswordsave_button:hover {
    background: #e7e7e7 !important;
}

.setPasswordicon_visibility_wrapper {
    position: absolute;
    float: right;
    left: 360px;
    top: 0;
    font-size: 25px;
    color: #B5C0C5 !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.setPasswordicon_visibility {
    color: #B5C0C5 !important;
}

.accordion_passwordValidationError {
    color: #EC5F5F;
    margin-left: 5px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 17px !important;
}

.accordion_passwordValidationSuccess {
    color: #B2C8A7;
    margin-left: 5px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 17px !important;
}

.accordion_passwordValidationError_icon {
    color: #EC5F5F;
}

.accordion_passwordValidationSuccess_icon {
    color: #19AC30;
}

.accordion_passwordValidationSuccess_icon_hidden {
    display: none !important;
}
.accordion_check-icon_wrapper{
    background: white !important;
    border-bottom: none !important;
    border-right: none !important;
    border-top: none !important;
}
.accordion_visibility-icon_wrapper{
    border-left: none !important;
    background: white !important;
}
.accordion_visibility-icon{
    color: #8F8F8F !important;
}
.accordion_passwordValidation_wrapper{
    display: flex !important;
     padding: 5px !important;
}