.headerSearch_input {
    border: 2px solid #eee;
    padding: 5px 10px 5px 50px;
    font-size: 18px;
    line-height: 25px;
    outline: 0;
    display: block;
    width: 100%;
    border-radius: 20px;
}

.headerNotification_input {
    color: #B4AA78;
    margin-right: 10px;
    padding: 1px;
}

.headerAvatar_input {
    color: #B4AA78;
    padding: 1px;
}

.headerSearch_input::placeholder {
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    font-style: normal;
    color: #BCBCBC;
}

.header-input_form {
    margin: 10px auto;
    position: relative;
    display: flex;
}

.header_icon_input {
    position: absolute;
    left: 15px;
    top: 0;
    font-size: 25px;
    color: #8F8F8F;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_profile_wrapper {
    float: right !important;
}

.header-notification_form {
    float: right !important;
    align-items: center;
}

.accountPage_button_wrapper {
    margin-bottom: 10px !important;
    align-items: flex-end;
    justify-self: flex-end;
    align-content: flex-end;
}

.accountPage_button {
    background-color: #3C7F9C;
    color: #ffff;
    border: none;
    padding: 10px;
    width: 206px;
    height: 42px;
    border-radius: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
}

.sidebar {
    background: linear-gradient(180deg, #4A708B 0%, rgba(93, 122, 142, 0.77) 31.82%, rgba(92, 121, 142, 0.61) 81.06%, rgba(69, 104, 130, 0.92) 97.19%);
    color: #fff;
    height: 100vh;
    width: 200px;
    transition: all 0.1s;
    position: fixed;
    left: 0;
    overflow: auto;
}

.top_section {
    display: flex;
    align-items: center;
    padding: 20px 15px;
}

.logo {
    font-size: 30px;
}

.bars {
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}

.link {
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.1s;
    text-decoration: none;
}

.link:hover {
    background: #E6E6E6;
    color: #6F899C;
    transition: all 0.1s;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.active {
    background: #E6E6E6;
    color: #6F899C;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.header_form-input {
    margin: 10px auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_icon_input {
    position: absolute;
    left: 15px;
    top: 0;
    font-size: 25px;
    color: #8F8F8F;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_search_input {
    border: 2px solid #eee !important;
    padding: 5px 10px 5px 50px !important;
    font-size: 18px !important;
    line-height: 30px !important;
    outline: 0;
    display: block;
    width: 100%;
    border-radius: 20px;
}

.header_search_input::placeholder {
    color: #BCBCBC;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
}

.icon,
.link_text {
    font-size: 20px;
}