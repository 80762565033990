.profileHeader_menu {
  margin-top: 50px !important;
}

.profileHeader_content {
  background: #E9E9E9 !important;
  align-content: center !important;
}

.profileHeader_icon_valute {
  color: #8F8F8F;
}

.profileHeader_text_amount {
  color: #565656 !important;
}

.profileHeader_divider {
  background: black !important;
  margin-top: 10px !important;
}

.language_switch_header {
  border: none;
  color: white;
  background-color: transparent;
  vertical-align: middle;
}

.language_switch_header img {
  width: 30px;
}

.language_switch_header:hover {
  transform: scale(1.1) !important;
  transition: all 0.5 ease !important;
}

.button_header {
  border: 1px solid white;
  width: 100px;
  border-radius: 20px;

  background: white;
  color: #1a1d1e;
}

.button_header:hover {
  background: transparent;
  color: white;
}