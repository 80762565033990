.table {
    width: 100%;
    /* border-collapse: collapse; */
    background-color: white;
}

.table thead tr th {
    background-color: white;
    color: #696969;
    font-weight: 700;
}

.table td,
.table th {
    padding: 12px 15px;
    text-align: center;
    border: 1px solid #6F899C;
}

.table th {
    background-color: #FFFFFF;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
}

.accountTable_body td {
    align-items: center;
    text-align: center;
    color: #696969;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.accountTable_delete_icon {
    color: #DE2C2C !important;
    cursor: pointer !important;
}

.accountTable_visibility_icon {
    color: #3C7F9C !important;
    cursor: pointer !important;
    margin-left: 5px;
    margin-right: 5px;
}

.accountTable_edit_icon {
    color: #00B2FF !important;
    cursor: pointer !important;
}

.accountTable_inactive_icon {
    color: #B8B8B8 !important;
}

.accountTable_active_icon {
    color: #19AC30 !important;
}

.accountTable_lock_icon {
    color: #CA9100 !important;
}

.accountTable_body tr td {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    align-self: center !important;
}

.accountTable_tableFooter {
    align-items: flex-end !important;
    justify-content: flex-end !important;
    border: 1px solid #6F899C;
    background-color: #fff;
    width: 100% !important;
}